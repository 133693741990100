import { ProdutoTableProps } from '~/pages/PdvOnline/types';

export const subTotalTable = (produtos: ProdutoTableProps[]): number => {
  const somaTotal = produtos.reduce((total, produto) => {
    const valTotalString = produto.val_total.toString();
    let valorFloat: number;

    if (valTotalString.length >= 8) {
      valorFloat =
        parseFloat(valTotalString.replace(/[^\d,]/g, '').replace(',', '.')) ||
        0;
    } else valorFloat = parseFloat(valTotalString.replace(',', '.')) || 0;

    if (!Number.isNaN(valorFloat)) return total + valorFloat;
    return total;
  }, 0);

  return somaTotal;
};
