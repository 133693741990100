import { Box } from '@material-ui/core';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import React, { useCallback } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useFocusTabFinalizacao } from '~/pages/PdvOnline/hooks/useFocusTabFinalizacao';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';
import { ButtonRow } from './styles';
import { useMediaQuery } from '~/hooks/useMediaQuery';

export const FormaPagamentoGrid: React.FC = () => {
  const {
    finalizadorasTable,
    handleAddFinalizadoraTable,
    finalizadorasPedido,
    handleAddFinalizadoraPedido,
  } = usePdvOnline();

  const { handleInputFocus } = useFocusTabFinalizacao();

  const lessThan1400 = useMediaQuery({ maxWidth: 1400 });

  const handleRemoveFinalizadora = useCallback(
    (id_finalizadora: string) => {
      const filterTable = finalizadorasTable.filter(
        (f) => f.id !== id_finalizadora,
      );

      const filterFinalizadoras = finalizadorasPedido.filter(
        (f) => f.id !== id_finalizadora,
      );

      handleAddFinalizadoraPedido(filterFinalizadoras);
      handleAddFinalizadoraTable(filterTable);
      handleInputFocus('des_tecla');
    },
    [
      finalizadorasPedido,
      finalizadorasTable,
      handleAddFinalizadoraPedido,
      handleAddFinalizadoraTable,
      handleInputFocus,
    ],
  );

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'tipo_finalizadora',
      headerName: 'Tipo Finalizadora',
      hide: true,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'num_prazo',
      headerName: 'Prazo',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      width: 150,
    },
    {
      field: 'condicao',
      headerName: 'Condição',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      width: 150,
    },
    {
      field: 'des_finalizadora',
      headerName: 'Finalizadora',
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      flex: 1,
    },
    {
      field: 'dta_vencto',
      headerName: 'Vencimento',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      width: 200,
    },
    {
      field: 'vlr_parcela',
      headerName: 'Valor',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      width: 150,
    },
    {
      field: '',
      headerName: 'Excluir',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'super-app-theme--cell',
      headerClassName: 'super-app-theme--header',
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;

        return (
          <ButtonRow
            type="button"
            onClick={() => handleRemoveFinalizadora(row.id)}
          >
            <FaRegTrashAlt size={25} style={{ color: '#e63c3c' }} />
          </ButtonRow>
        );
      },
    },
  ];

  return (
    <Box sx={{ height: lessThan1400 ? 160 : 200 }}>
      <DataGrid
        rows={finalizadorasTable}
        columns={columns}
        disableColumnFilter
        disableColumnSelector
        disableSelectionOnClick
        disableColumnMenu
        hideFooterPagination
        hideFooterRowCount
        hideFooterSelectedRowCount
        rowHeight={lessThan1400 ? 35 : 50}
        hideFooter
        localeText={{
          noRowsLabel: 'Nenhuma finalizadora adicionada',
          columnMenuLabel: 'Menu',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Esconder',
          columnMenuUnsort: 'Não ordenar',
          columnMenuSortAsc: 'Ordernar ASC',
          columnMenuSortDesc: 'Ordernar DESC',
          columnMenuShowColumns: 'Mostrar colunas',
        }}
      />
    </Box>
  );
};
