export const contarCasasDecimais = (str: string): number => {
  const casasDecimaisMatch = /^\d+,\d+$/.exec(str);

  if (casasDecimaisMatch) {
    const casasDecimais = casasDecimaisMatch[0].split(',')[1];
    return casasDecimais.length;
  }

  return 0;
};
