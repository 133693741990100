import { DataGrid, GridColumns } from '@material-ui/data-grid';
import React, { useCallback } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useFocusTabProduto } from '~/pages/PdvOnline/hooks/useFocusTabProduto';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';
import { ProdutoTableProps } from '~/pages/PdvOnline/types';
import { ButtonRow } from './styles';

export const ProdutoGrid: React.FC = () => {
  const { produtosTable, handleAddProdutoTable, pedido, handlePedido } =
    usePdvOnline();
  const { handleInputFocus } = useFocusTabProduto();

  const handleRemoveProduto = useCallback(
    (id_produto: string) => {
      const filterTable = produtosTable.filter(
        (prod: ProdutoTableProps) => prod.id !== id_produto,
      );

      const filterPedido = pedido.filter((p) => p.id !== id_produto);

      handleAddProdutoTable(filterTable);
      handlePedido(filterPedido);
      handleInputFocus('leitor');
    },
    [
      handleAddProdutoTable,
      handleInputFocus,
      handlePedido,
      pedido,
      produtosTable,
    ],
  );

  const colunasProdutos: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'cod_produto',
      headerName: 'Código PLU',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      width: 130,
    },
    { field: 'des_produto', headerName: 'Descrição', sortable: false, flex: 1 },
    {
      field: 'des_unidade_venda',
      headerName: 'Unidade',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      width: 90,
    },
    {
      field: 'qtd_produto',
      headerName: 'Qtd Embalagem',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      width: 140,
    },
    {
      field: 'val_preco',
      headerName: 'Valor Unitário',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      width: 120,
    },
    {
      field: 'val_desconto',
      headerName: 'Valor Desconto',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      width: 130,
    },
    {
      field: 'val_total',
      headerName: 'Valor Total',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      width: 115,
    },
    {
      field: '',
      headerName: 'Ação',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'super-app-theme--cell',
      headerClassName: 'super-app-theme--header',
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;

        return (
          <ButtonRow type="button" onClick={() => handleRemoveProduto(row.id)}>
            <FaRegTrashAlt size={25} style={{ color: '#e63c3c' }} />
          </ButtonRow>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={produtosTable}
      columns={colunasProdutos}
      disableColumnFilter
      disableColumnSelector
      disableSelectionOnClick
      disableColumnMenu
      hideFooterPagination
      hideFooterRowCount
      hideFooterSelectedRowCount
      style={{ backgroundColor: '#f7f7f7' }}
      localeText={{
        noRowsLabel: 'Nenhum produto adicionado',
        columnMenuLabel: 'Menu',
        columnMenuFilter: 'Filtrar',
        columnMenuHideColumn: 'Esconder',
        columnMenuUnsort: 'Não ordenar',
        columnMenuSortAsc: 'Ordernar ASC',
        columnMenuSortDesc: 'Ordernar DESC',
        columnMenuShowColumns: 'Mostrar colunas',
      }}
    />
  );
};
