import styled from 'styled-components';

export const GridContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(auto, max-content);
  gap: 1rem;
  margin: 0 auto;
`;

export const ProdutoContainer = styled.div`
  grid-column: span 4;
  padding: 0.625rem;

  @media only screen and (max-width: 1400px) {
    padding: 0.3125rem;
  }
`;

export const TableContainer = styled.div`
  grid-column: span 8;
  padding: 0.625rem;
  grid-row: span 7;

  @media only screen and (max-width: 1400px) {
    grid-row: span none;
  }
`;

export const ProdutoContent = styled.section`
  display: flex;
  flex-direction: column;

  .inputLeitor {
    display: flex;
    height: 3.125rem;
    width: 100%;
    font-size: x-large;

    text-align: left !important;

    -moz-appearance: textfield !important;
    appearance: textfield !important;
  }

  .inputLeitor::-webkit-inner-spin-button,
  .inputLeitor::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
  }
`;

export const TableContent = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #bfe7c3;
  margin-top: 1.25rem;
  border-radius: 0.3125rem;

  @media only screen and (max-width: 1400px) {
    margin-top: 0.625rem;
  }
`;

export const ValuesContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.625rem;
  gap: 1.25rem;

  @media only screen and (max-width: 1600px) {
    width: 100%;

    div {
      flex-direction: column;
    }
  }

  label {
    font-size: medium;
    margin-right: 0.375rem;
    user-select: none;
  }

  div {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 1600px) {
      align-items: flex-start;

      svg,
      i {
        margin-left: 0.875rem;
      }
    }
  }
`;

export const SubtotalContainer = styled.span`
  border-bottom: 0.0938rem solid black;

  p {
    font-size: medium;
    font-weight: 600;
    user-select: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  gap: 0.9375rem;
  margin-top: 0.9375rem;

  .clearButton {
    background-color: #2a79e3;

    &:hover {
      background-color: #286dc9;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #2a79e3;
      opacity: 60%;
    }
  }

  .addButton {
    background-color: #009e0e;

    &:hover {
      background-color: #007c0b;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #009e0e;
      opacity: 60%;
    }
  }
`;

export const Button = styled.button`
  border: none;
  padding: 0.625rem;
  border-radius: 0.3125rem;

  text-transform: uppercase;
  font-size: medium;
  color: white;

  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

export const SubtotalTableContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  background-color: #ddeaf8;
  border-radius: 0.4375rem;

  margin-top: 0.8125rem;

  p {
    font-size: xx-large;
    text-align: center;
    padding: 0.9375rem;
    margin: 0;
    font-weight: 600;
    margin-right: 0.625rem;
    text-transform: uppercase;
  }
`;
