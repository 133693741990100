import React, { ReactNode } from 'react';

interface TabProps {
  label: string;
  children: ReactNode;
}

export const Tab: React.FC<TabProps> = ({ label, children }) => {
  return <>{children}</>;
};
