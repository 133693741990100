import { getEtiquetaBalanca } from '~/pages/PdvOnline/services/etiqueta-balanca';
import { strPadZeroL } from './strPadZeroL';

interface ParametroLoja {
  qtd_dig_cod_bal: number;
  tipo_totalizador_etq_bal: number;
  flg_sem_dig_verif_bal: boolean;
  cod_loja: number;
}

interface ResponseCheck {
  qtdCalculate: number;
  edtCodPlu: string;
  valVenda: number;
}

export const processaEtiquetaBalanca = async (
  edtCodPlu: string,
  parametroLoja: ParametroLoja,
): Promise<boolean | ResponseCheck> => {
  let plu = '';
  let qtd = '';
  let qtdEmb = 0;
  let qtdEmbTot = 0;
  let qtdEmbTotal = 0;
  let qtdCalculate = 0;

  if ((edtCodPlu || '').startsWith('2') && edtCodPlu.length === 13) {
    switch (parametroLoja.qtd_dig_cod_bal) {
      case 0:
        if (parametroLoja.flg_sem_dig_verif_bal)
          plu = strPadZeroL(edtCodPlu.substring(1, 5), 12);
        else plu = strPadZeroL(edtCodPlu.substring(1, 5), 13);
        qtd = edtCodPlu.substring(5, 12);
        break;
      case 1:
        if (parametroLoja.flg_sem_dig_verif_bal)
          plu = strPadZeroL(edtCodPlu.substring(1, 6), 12);
        else plu = strPadZeroL(edtCodPlu.substring(1, 6), 13);
        qtd = edtCodPlu.substring(6, 12);
        break;
      case 2:
        if (parametroLoja.flg_sem_dig_verif_bal)
          plu = strPadZeroL(edtCodPlu.substring(1, 7), 12);
        else plu = strPadZeroL(edtCodPlu.substring(1, 7), 13);
        qtd = edtCodPlu.substring(7, 12);
        break;
      default:
        break;
    }
  }

  if (parametroLoja.flg_sem_dig_verif_bal) edtCodPlu = `${plu}-`;
  else edtCodPlu = plu;

  const valVenda = await getEtiquetaBalanca(parametroLoja.cod_loja, edtCodPlu);

  if (typeof valVenda !== 'number') return false;

  if (parametroLoja.tipo_totalizador_etq_bal === 0) {
    qtdEmb = parseFloat(qtd) / 100;
    qtdEmbTot = valVenda / 1000;
    qtdEmbTotal = qtdEmb / qtdEmbTot / 1000;
    qtdCalculate = qtdEmbTotal;
  } else {
    qtdEmb = parseFloat(qtd) / 1000;
    qtdCalculate = qtdEmb;
  }

  return { qtdCalculate, edtCodPlu, valVenda };
};
