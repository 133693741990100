export const formatarQtdIpv = (mascara: string, valor: string): string => {
  if (valor === '') return '0,000';

  const valorNumerico = parseFloat(valor.replace(/\./g, '').replace(',', '.'));

  if (Number.isNaN(valorNumerico)) return '0,000';

  const valorFormatado = valorNumerico.toLocaleString('en-US', {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });

  return mascara.replace(/0,000/, valorFormatado);
};
